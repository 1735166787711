import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class CollectingInformation extends PureComponent {

	render() {
		const { id } = this.props;

		return (
			<>
				<p className="privacy-policy__heading" id={`section-${id}`}>
					{id}. WHAT INFORMATION DO WE COLLECT?
				</p>
				<p className="privacy-policy__heading">
					Information automatically collected
				</p>
				<p>
					<span className="privacy-policy__highlight">In Short:</span>
					Some information — such as your Internet Protocol (IP) address and/or browser and device characteristics — is collected automatically when you visit our Services.
				</p>
				<p>
					We automatically collect certain information when you visit, use or navigate the Services. This information does not reveal your specific identity (like your name or contact information) but may include device and usage information, such as your IP address, browser and device characteristics, operating system, language preferences, referring URLs, device name, country, location, information about who and when you use our Services and other technical information. This information is primarily needed to maintain the security and operation of our Services, and for our internal analytics and reporting purposes.
				</p>
				<p>
					Like many businesses, we also collect information through cookies and similar technologies.
				</p>
				<p>
					The information we collect includes:
				</p>
				<ul>
					<li>
						<span className="privacy-policy__highlight">Log and Usage Data:</span>
						Log and usage data is service-related, diagnostic usage and performance information our servers automatically collect when you access or use our Services and which we record in log files. Depending on how you interact with us, this log data may include your IP address, device information, browser type and settings and information about your activity in the Services (such as the date/time stamps associated with your usage, pages and files viewed, searches and other actions you take such as which features you use), device event information (such as system activity, error reports (sometimes called 'crash dumps') and hardware settings).
					</li>
				</ul>
				<p className="privacy-policy__heading">
					Information collected through our App
				</p>
				<p>
					<span className="privacy-policy__highlight">In Short:</span>
					We collect information regarding your geo-location, mobile device, push notifications, when you use our App.
				</p>
				<p>
					If you use our App, we also collect the following information:
				</p>
				<ul>
					<li>
						<span className="privacy-policy__highlight">Mobile Device Access:</span>
						We may request access or permission to certain features from your mobile device, including your mobile device's camera, storage, and other features. If you wish to change our access or permissions, you may do so in your device's settings.
					</li>
					<li>
						<span className="privacy-policy__highlight">Mobile Device Data:</span>
						We automatically collect device information (such as your mobile device ID, model and manufacturer), operating system, version information and system configuration information, device and application identification numbers, browser type and version, hardware model Internet service provider and/or mobile carrier, and Internet Protocol (IP) address (or proxy server). If you are using our App, we may also collect information about the phone network associated with your mobile device, your mobile device's operating system or platform, the type of mobile device you use, your mobile device's unique device ID and information about the features of our App you accessed.
					</li>
					<li>
						<span className="privacy-policy__highlight">Push Notifications:</span>
						We may request to send you push notifications regarding your account or certain features of the App. If you wish to opt-out from receiving these types of communications, you may turn them off in your device's settings.
					</li>
				</ul>
				<p>
					The information is primarily needed to maintain the security and operation of our App, for troubleshooting and for our internal analytics and reporting purposes.
				</p>
			</>
		);
	}
}

CollectingInformation.propTypes = {
	id: PropTypes.number
};

export default CollectingInformation;