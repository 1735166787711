// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.privacy-policy__wrapper {
	padding: 3.5rem 1rem;
	height: 100%;
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-around;
	align-items: center;
}

.privacy-policy__container {
	display: flex;
	flex-flow: column;
	padding: 3rem 1rem;
	width: 100%;
}

.privacy-policy__container a, .privacy-policy__container a:hover, .privacy-policy__container a:focus, .privacy-policy__container a:visited, .privacy-policy__container a:active {
	text-decoration: none;
	color: #f49904;
	font-weight: 900;
}

.privacy-policy__title {
	font-size: 3rem;
	font-weight: 600;
}

.privacy-policy__subtitle {
	font-size: 1rem;
	font-weight: 300;
	color: #9b9b9b;
}

.privacy-policy__link {
	display: block;
	margin: 1em 0;
	color: #f49904;
	cursor: pointer;
}

.privacy-policy__heading {
	font-weight: bold;
}

.privacy-policy__highlight {
	color: #9b9b9b;
	font-style: italic;
	margin-right: 8px;
}`, "",{"version":3,"sources":["webpack://./src/pages/privacy/PrivacyPolicySection/privacyPolicy.css"],"names":[],"mappings":"AAAA;CACC,oBAAoB;CACpB,YAAY;CACZ,WAAW;CACX,aAAa;CACb,wBAAwB;CACxB,6BAA6B;CAC7B,mBAAmB;AACpB;;AAEA;CACC,aAAa;CACb,iBAAiB;CACjB,kBAAkB;CAClB,WAAW;AACZ;;AAEA;CACC,qBAAqB;CACrB,cAAc;CACd,gBAAgB;AACjB;;AAEA;CACC,eAAe;CACf,gBAAgB;AACjB;;AAEA;CACC,eAAe;CACf,gBAAgB;CAChB,cAAc;AACf;;AAEA;CACC,cAAc;CACd,aAAa;CACb,cAAc;CACd,eAAe;AAChB;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,cAAc;CACd,kBAAkB;CAClB,iBAAiB;AAClB","sourcesContent":[".privacy-policy__wrapper {\n\tpadding: 3.5rem 1rem;\n\theight: 100%;\n\twidth: 100%;\n\tdisplay: flex;\n\tflex-flow: column nowrap;\n\tjustify-content: space-around;\n\talign-items: center;\n}\n\n.privacy-policy__container {\n\tdisplay: flex;\n\tflex-flow: column;\n\tpadding: 3rem 1rem;\n\twidth: 100%;\n}\n\n.privacy-policy__container a, .privacy-policy__container a:hover, .privacy-policy__container a:focus, .privacy-policy__container a:visited, .privacy-policy__container a:active {\n\ttext-decoration: none;\n\tcolor: #f49904;\n\tfont-weight: 900;\n}\n\n.privacy-policy__title {\n\tfont-size: 3rem;\n\tfont-weight: 600;\n}\n\n.privacy-policy__subtitle {\n\tfont-size: 1rem;\n\tfont-weight: 300;\n\tcolor: #9b9b9b;\n}\n\n.privacy-policy__link {\n\tdisplay: block;\n\tmargin: 1em 0;\n\tcolor: #f49904;\n\tcursor: pointer;\n}\n\n.privacy-policy__heading {\n\tfont-weight: bold;\n}\n\n.privacy-policy__highlight {\n\tcolor: #9b9b9b;\n\tfont-style: italic;\n\tmargin-right: 8px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
