import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Section from '../../../components/Section';
import ContactForm from '../../../components/ContactForm';

import './contact.css';

class ContactSection extends PureComponent {
	render() {
		const { id, featured, fullHeight } = this.props;

		return (
			<Section id={id} featured={featured} fullHeight={fullHeight}>
				<div className="contact__wrapper">
					<div className="contact__title">
						Contact us
					</div>
					<div className="contact__container">
						<ContactForm />
					</div>
				</div>
			</Section>
		);
	}
}

ContactSection.propTypes = {
	id: PropTypes.string
};

export default ContactSection;