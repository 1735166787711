import React, { PureComponent } from 'react';

import BrandSection from './BrandSection';
import ServicesSection from './ServicesSection';
import ContactSection from './ContactSection';
import Footer from '../../components/Footer';

import './home.css';

class Home extends PureComponent {

	render() {
		return (
			<main className="home">
				<BrandSection id="brand-section" />
				<ServicesSection id="services-section" featured fullHeight />
				<ContactSection id="contact-section" />
				<Footer />
			</main>
		);
	}

}

export default Home;
