import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class UsingInformation extends PureComponent {

	render() {
		const { id } = this.props;

		return (
			<>
				<p className="privacy-policy__heading" id={`section-${id}`}>
					{id}. HOW DO WE USE YOUR INFORMATION?
				</p>
				<p>
					<span className="privacy-policy__highlight">In Short:</span>
					We process your information for purposes based on legitimate business interests, the fulfillment of our contract with you, compliance with our legal obligations, and/or your consent.
				</p>
				<p>
					We use personal information collected via our Services for a variety of business purposes described below. We process your personal information for these purposes in reliance on our legitimate business interests, in order to enter into or perform a contract with you, with your consent, and/or for compliance with our legal obligations. We indicate the specific processing grounds we rely on next to each purpose listed below.
				</p>
				<p>
					We use the information we collect or receive:
				</p>
				<ul>
					<li>
						<span className="privacy-policy__highlight">To post testimonials:</span>
						We post testimonials on our Services that may contain personal information. Prior to posting a testimonial, we will obtain your consent to use your name and the consent of the testimonial. If you wish to update, or delete your testimonial, please contact us at <a href="mailto:dev@acceligent.com">dev@acceligent.com</a> and be sure to include your name, testimonial location, and contact information.
					</li>
					<li>
						<span className="privacy-policy__highlight">Request feedback:</span>
						We may use your information to request feedback and to contact you about your use of our Services.
					</li>
					<li>
						<span className="privacy-policy__highlight">To enable user-to-user communications:</span>
						We may use your information in order to enable user-to-user communications with each user's consent.
					</li>
					<li>
						<span className="privacy-policy__highlight">To manage user accounts:</span>
						We may use your information for the purposes of managing our account and keeping it in working order.
					</li>
					<li>
						<span className="privacy-policy__highlight">To send administrative information to you:</span>
						We may use your personal information to send you product, service and new feature information and/or information about changes to our terms, conditions, and policies.
					</li>
					<li>
						<span className="privacy-policy__highlight">To protect our Services:</span>
						We may use your information as part of our efforts to keep our Services safe and secure (for example, for fraud monitoring and prevention).
					</li>
					<li>
						<span className="privacy-policy__highlight">To enforce our terms, conditions and policies for business purposes, to comply with legal and regulatory requirements or in connection with our contract.</span>
					</li>
					<li>
						<span className="privacy-policy__highlight">To respond to legal requests and prevent harm:</span>
						If we receive a subpoena or other legal request, we may need to inspect the data we hold to determine how to respond.
					</li>
				</ul>
			</>
		);
	}
}

UsingInformation.propTypes = {
	id: PropTypes.number
};

export default UsingInformation;