import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class GoogleMaps extends PureComponent {

	render() {
		const { id } = this.props;

		return (
			<>
				<p className="privacy-policy__heading" id={`section-${id}`}>
					{id}. DO WE USE GOOGLE MAPS?
				</p>
				<p>
					<span className="privacy-policy__highlight">In Short:</span>
					Yes, we use Google Maps for the purpose of providing better service.
				</p>
				<p>
					This Website or App uses Google Maps APIs which is subject to Google's Terms of Service. You may find the Google Maps APIs Terms of Service here. To find out more about Google’s Privacy Policy, please refer to this link.
				</p>
			</>
		);
	}
}

GoogleMaps.propTypes = {
	id: PropTypes.number
};

export default GoogleMaps;