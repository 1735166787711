// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `body, html {
	margin: 0;
	padding: 0;
	font-family: Lato, "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
		"Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
		sans-serif !important;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	width: 100vw;
	max-width: 100vw;
	height: 100%;
	font-size: 16px;
}

code {
	font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
		monospace;
}

#root { 
	width: inherit;
	height: inherit;
	overflow: hidden;
	overflow-y: scroll;
}

* {
	box-sizing: border-box !important;
}`, "",{"version":3,"sources":["webpack://./src/index.css"],"names":[],"mappings":"AAAA;CACC,SAAS;CACT,UAAU;CACV;;uBAEsB;CACtB,mCAAmC;CACnC,kCAAkC;CAClC,YAAY;CACZ,gBAAgB;CAChB,YAAY;CACZ,eAAe;AAChB;;AAEA;CACC;WACU;AACX;;AAEA;CACC,cAAc;CACd,eAAe;CACf,gBAAgB;CAChB,kBAAkB;AACnB;;AAEA;CACC,iCAAiC;AAClC","sourcesContent":["body, html {\n\tmargin: 0;\n\tpadding: 0;\n\tfont-family: Lato, \"Roboto\", -apple-system, BlinkMacSystemFont, \"Segoe UI\", \"Oxygen\",\n\t\t\"Ubuntu\", \"Cantarell\", \"Fira Sans\", \"Droid Sans\", \"Helvetica Neue\",\n\t\tsans-serif !important;\n\t-webkit-font-smoothing: antialiased;\n\t-moz-osx-font-smoothing: grayscale;\n\twidth: 100vw;\n\tmax-width: 100vw;\n\theight: 100%;\n\tfont-size: 16px;\n}\n\ncode {\n\tfont-family: source-code-pro, Menlo, Monaco, Consolas, \"Courier New\",\n\t\tmonospace;\n}\n\n#root { \n\twidth: inherit;\n\theight: inherit;\n\toverflow: hidden;\n\toverflow-y: scroll;\n}\n\n* {\n\tbox-sizing: border-box !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
