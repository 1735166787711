import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class PrivacyRights extends PureComponent {

	render() {
		const { id } = this.props;

		return (
			<>
				<p className="privacy-policy__heading" id={`section-${id}`}>
					{id}. WHAT ARE YOUR PRIVACY RIGHTS?
				</p>
				<p>
					<span className="privacy-policy__highlight">In Short:</span>
					You may review, change, or terminate your account at any time.
				</p>
				<p>
					If you are resident in the European Economic Area and you believe we are unlawfully processing your personal information, you also have the right to complain to your local data protection supervisory authority. You can find their contact details <a href="http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm">here</a>.

				</p>
				<p>
					If you are resident in Switzerland, the contact details for the data protection authorities are available <a href="https://www.edoeb.admin.ch/edoeb/en/home.html">here</a>.
				</p>
				<p className="privacy-policy__heading">
					Account Information
				</p>
				<p>
					If you would at any time like to review or change the information in your account or terminate your account, you can:
				</p>
				<ul>
					<li>
						Log in to your account settings and update your user account.
					</li>
					<li>
						Contact us using the contact information provided.
					</li>
				</ul>
				<p>
					Upon your request to terminate your account, we will deactivate or delete your account and information from our active databases. However, we may retain some information in our files to prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use and/or comply with applicable legal requirements.
				</p>
				<p>
					Opting out of email marketing: You can unsubscribe from our marketing email list at any time by clicking on the unsubscribe link in the emails that we send or by contacting us using the details provided below. You will then be removed from the marketing email list – however, we may still communicate with you, for example to send you service-related emails that are necessary for the administration and use of your account, to respond to service requests, or for other non-marketing purposes. To otherwise opt-out, you may:
				</p>
				<ul>
					<li>
						Contact us using the contact information provided.
					</li>
				</ul>
			</>
		);
	}
}

PrivacyRights.propTypes = {
	id: PropTypes.number
};

export default PrivacyRights;