// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.home {
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
	width: 100%;
	margin: auto;
}

.home__section-wrapper {
	display: flex;
	flex-flow: column nowrap;
	height: 100%;
	width: 100%;
	margin: auto;
}

.home__section-wrapper--narrow {
	max-width: 1366px;
}

.home__section {
	height: 90vh;
	width: 100vw;
	display: block;
	flex: 1 0 auto;
}

.home__section--full-height {
	height: 100vh;
}

.home__section--automatic-height {
	height: auto;
}

.home__section--featured {
	color: white;
	background-color: #F49904;
}

@media only screen and (min-width: 320px) and (max-width: 479px) { 

}

@media only screen and (min-width: 480px) and (max-width: 767px) { 
}

@media only screen and (min-width: 768px) and (max-width: 991px) { 
	
}

@media only screen and (min-width: 992px) and (max-width: 1999px) { 
	.home__section-wrapper {
		max-width: 1366px;
	}
}`, "",{"version":3,"sources":["webpack://./src/pages/home/home.css"],"names":[],"mappings":"AAAA;CACC,aAAa;CACb,wBAAwB;CACxB,YAAY;CACZ,WAAW;CACX,YAAY;AACb;;AAEA;CACC,aAAa;CACb,wBAAwB;CACxB,YAAY;CACZ,WAAW;CACX,YAAY;AACb;;AAEA;CACC,iBAAiB;AAClB;;AAEA;CACC,YAAY;CACZ,YAAY;CACZ,cAAc;CACd,cAAc;AACf;;AAEA;CACC,aAAa;AACd;;AAEA;CACC,YAAY;AACb;;AAEA;CACC,YAAY;CACZ,yBAAyB;AAC1B;;AAEA;;AAEA;;AAEA;AACA;;AAEA;;AAEA;;AAEA;CACC;EACC,iBAAiB;CAClB;AACD","sourcesContent":[".home {\n\tdisplay: flex;\n\tflex-flow: column nowrap;\n\theight: 100%;\n\twidth: 100%;\n\tmargin: auto;\n}\n\n.home__section-wrapper {\n\tdisplay: flex;\n\tflex-flow: column nowrap;\n\theight: 100%;\n\twidth: 100%;\n\tmargin: auto;\n}\n\n.home__section-wrapper--narrow {\n\tmax-width: 1366px;\n}\n\n.home__section {\n\theight: 90vh;\n\twidth: 100vw;\n\tdisplay: block;\n\tflex: 1 0 auto;\n}\n\n.home__section--full-height {\n\theight: 100vh;\n}\n\n.home__section--automatic-height {\n\theight: auto;\n}\n\n.home__section--featured {\n\tcolor: white;\n\tbackground-color: #F49904;\n}\n\n@media only screen and (min-width: 320px) and (max-width: 479px) { \n\n}\n\n@media only screen and (min-width: 480px) and (max-width: 767px) { \n}\n\n@media only screen and (min-width: 768px) and (max-width: 991px) { \n\t\n}\n\n@media only screen and (min-width: 992px) and (max-width: 1999px) { \n\t.home__section-wrapper {\n\t\tmax-width: 1366px;\n\t}\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
