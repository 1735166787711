import React, { PureComponent } from 'react';

import PrivacyPolicySection from './PrivacyPolicySection';
import Footer from '../../components/Footer';

import './privacy.css';

class Home extends PureComponent {

	render() {
		return (
			<main className="privacy">
				<PrivacyPolicySection id="privacy-policy-section" />
				<Footer />
			</main>
		);
	}

}

export default Home;
