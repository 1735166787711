import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Section from '../../../components/Section';
import More from './More';

import './brand.css';

class BrandSection extends PureComponent {
	render() {
		const { id } = this.props;
		return (
			<Section id={id}>
				<div className="brand__wrapper">
					<div className="brand__logo">
						<div className="brand__logo-text">
							Build better with
							<div className="brand__logo-text--featured">Acceligent</div>
						</div>
						<div className="brand__logo-image">
							<img src="/assets/laptop.png" alt="laptop" />
						</div>
					</div>
					<More />
				</div>
			</Section>
		);
	}
}

BrandSection.propTypes = {
	id: PropTypes.string
};

export default BrandSection;