import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class SharingInformation extends PureComponent {

	render() {
		const { id } = this.props;

		return (
			<>
				<p className="privacy-policy__heading" id={`section-${id}`}>
					{id}. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
				</p>
				<p>
					<span className="privacy-policy__highlight">In Short:</span>
					We only share information with your consent, to comply with laws, to provide you with services, to protect your rights, or to fulfill business obligations.
				</p>
				<p>
					We may process or share your data that we hold based on the following legal basis:
				</p>
				<ul>
					<li>
						<span className="privacy-policy__highlight">Consent:</span>
						We may process your data if you have given us specific consent to use your personal information in a specific purpose.
					</li>
					<li>
						<span className="privacy-policy__highlight">Legitimate Interests:</span>
						We may process your data when it is reasonably necessary to achieve our legitimate business interests.
					</li>
					<li>
						<span className="privacy-policy__highlight">Performance of a Contract:</span>
						Where we have entered into a contract with you, we may process your personal information to fulfill the terms of our contract.
					</li>
					<li>
						<span className="privacy-policy__highlight">Legal Obligations:</span>
						We may disclose your information where we are legally required to do so in order to comply with applicable law, governmental requests, a judicial proceeding, court order, or legal process, such as in response to a court order or a subpoena (including in response to public authorities to meet national security or law enforcement requirements).
					</li>
					<li>
						<span className="privacy-policy__highlight">Vital Interests:</span>
						We may disclose your information where we believe it is necessary to investigate, prevent, or take action regarding potential violations of our policies, suspected fraud, situations involving potential threats to the safety of any person and illegal activities, or as evidence in litigation in which we are involved.
					</li>
				</ul>

				<p>
					More specifically, we may need to process your data or share your personal information in the following situations:
				</p>
				<ul>
					<li>
						<span className="privacy-policy__highlight">Business Transfers:</span>
						We may share or transfer your information in connection with, or during negotiations of, any merger, sale of company assets, financing, or acquisition of all or a portion of our business to another company.
					</li>
					<li>
						<span className="privacy-policy__highlight">Other Users:</span>
						When you share personal information (for example, by posting comments, contributions or other content to the Services) or otherwise interact with public areas of the Services, such personal information may be viewed by all users and may be publicly made available outside the Services in perpetuity. Similarly, other users will be able to view descriptions of your activity, communicate with you within our Services, and view your profile.
					</li>
				</ul>
			</>
		);
	}
}

SharingInformation.propTypes = {
	id: PropTypes.number
};

export default SharingInformation;