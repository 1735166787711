// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact__wrapper {
	padding: 3.5rem 1rem;
	height: 100%;
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	justify-content: space-around;
	align-items: center;
}

.contact__container {
	display: flex;
	flex-flow: row wrap;
	padding: 3rem 1rem;
	width: 100%;
}

.contact__title {
	font-size: 3rem;
	font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/pages/home/ContactSection/contact.css"],"names":[],"mappings":"AAAA;CACC,oBAAoB;CACpB,YAAY;CACZ,WAAW;CACX,aAAa;CACb,wBAAwB;CACxB,6BAA6B;CAC7B,mBAAmB;AACpB;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,kBAAkB;CAClB,WAAW;AACZ;;AAEA;CACC,eAAe;CACf,gBAAgB;AACjB","sourcesContent":[".contact__wrapper {\n\tpadding: 3.5rem 1rem;\n\theight: 100%;\n\twidth: 100%;\n\tdisplay: flex;\n\tflex-flow: column nowrap;\n\tjustify-content: space-around;\n\talign-items: center;\n}\n\n.contact__container {\n\tdisplay: flex;\n\tflex-flow: row wrap;\n\tpadding: 3rem 1rem;\n\twidth: 100%;\n}\n\n.contact__title {\n\tfont-size: 3rem;\n\tfont-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
