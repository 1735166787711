// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contact-form {
	height: 100%;
	width: 100%;
}

.contact-form form {
	display: flex;
	flex-flow: column nowrap;
	max-width: 456px;
	margin: auto;
}

.form-group {
	display: flex;
	flex-flow: column nowrap;
	align-items: flex-start;
	margin-bottom: 1rem;
}

.form-group label {
	margin-bottom: .5rem;
	font-weight: 1rem;
	font-weight: 600;
	color: rgba(0,0,0,.84);
}

.form-group input,
.form-group textarea {
	color: rgba(0,0,0,.84);
	background: white;
	border: 1px solid rgb(214, 214, 214);
	border-radius: .25rem;
	padding: .5rem;
	width: 100%;
}

button[type="submit"] {
	padding: .75rem 1.25rem;
	font-weight: 600;
	text-transform: uppercase;
	border-radius: .25rem;
	border: none;
	outline: none;
	background: rgba(0,0,0,.84);
	color: white;
	width: 100px;
	transition: background-color .1s ease-in;
	cursor: pointer;
}

button[type="submit"]:hover {
	background: #F49904;
}`, "",{"version":3,"sources":["webpack://./src/components/ContactForm/contactForm.css"],"names":[],"mappings":"AAAA;CACC,YAAY;CACZ,WAAW;AACZ;;AAEA;CACC,aAAa;CACb,wBAAwB;CACxB,gBAAgB;CAChB,YAAY;AACb;;AAEA;CACC,aAAa;CACb,wBAAwB;CACxB,uBAAuB;CACvB,mBAAmB;AACpB;;AAEA;CACC,oBAAoB;CACpB,iBAAiB;CACjB,gBAAgB;CAChB,sBAAsB;AACvB;;AAEA;;CAEC,sBAAsB;CACtB,iBAAiB;CACjB,oCAAoC;CACpC,qBAAqB;CACrB,cAAc;CACd,WAAW;AACZ;;AAEA;CACC,uBAAuB;CACvB,gBAAgB;CAChB,yBAAyB;CACzB,qBAAqB;CACrB,YAAY;CACZ,aAAa;CACb,2BAA2B;CAC3B,YAAY;CACZ,YAAY;CACZ,wCAAwC;CACxC,eAAe;AAChB;;AAEA;CACC,mBAAmB;AACpB","sourcesContent":[".contact-form {\n\theight: 100%;\n\twidth: 100%;\n}\n\n.contact-form form {\n\tdisplay: flex;\n\tflex-flow: column nowrap;\n\tmax-width: 456px;\n\tmargin: auto;\n}\n\n.form-group {\n\tdisplay: flex;\n\tflex-flow: column nowrap;\n\talign-items: flex-start;\n\tmargin-bottom: 1rem;\n}\n\n.form-group label {\n\tmargin-bottom: .5rem;\n\tfont-weight: 1rem;\n\tfont-weight: 600;\n\tcolor: rgba(0,0,0,.84);\n}\n\n.form-group input,\n.form-group textarea {\n\tcolor: rgba(0,0,0,.84);\n\tbackground: white;\n\tborder: 1px solid rgb(214, 214, 214);\n\tborder-radius: .25rem;\n\tpadding: .5rem;\n\twidth: 100%;\n}\n\nbutton[type=\"submit\"] {\n\tpadding: .75rem 1.25rem;\n\tfont-weight: 600;\n\ttext-transform: uppercase;\n\tborder-radius: .25rem;\n\tborder: none;\n\toutline: none;\n\tbackground: rgba(0,0,0,.84);\n\tcolor: white;\n\twidth: 100px;\n\ttransition: background-color .1s ease-in;\n\tcursor: pointer;\n}\n\nbutton[type=\"submit\"]:hover {\n\tbackground: #F49904;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
