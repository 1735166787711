import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class InternationalInformation extends PureComponent {

	render() {
		const { id } = this.props;

		return (
			<>
				<p className="privacy-policy__heading" id={`section-${id}`}>
					{id}. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
				</p>
				<p>
					<span className="privacy-policy__highlight">In Short:</span>
					We may transfer, store, and process your information in countries other than your own.
				</p>
				<p>
					Our servers are located in US and EU. If you are accessing our Services from outside, please be aware that your information may be transferred to, stored, and processed by us in our facilities and by those third parties with whom we may share your personal information (see "WILL YOUR INFORMATION BE SHARED WITH ANYONE?" above), in and other countries.
				</p>
				<p>
					If you are a resident in the European Economic Area, then these countries may not necessarily have data protection laws or other similar laws as comprehensive as those in your country. We will however take all necessary measures to protect your personal information in accordance with this privacy notice and applicable law.
				</p>
			</>
		);
	}
}

InternationalInformation.propTypes = {
	id: PropTypes.number
};

export default InternationalInformation;