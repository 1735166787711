import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class California extends PureComponent {

	render() {
		const { id } = this.props;

		return (
			<>
				<p className="privacy-policy__heading" id={`section-${id}`}>
					{id}. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
				</p>
				<p>
					If you have questions or comments about this notice, you may email us at <a href="mailto:dev@acceligent.com">dev@acceligent.com</a>
				</p>
			</>
		);
	}
}

California.propTypes = {
	id: PropTypes.number
};

export default California;