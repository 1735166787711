import React, { PureComponent } from 'react';
import moment from 'moment';
import { Link } from 'react-router-dom';

import './header.css';

moment.locale('hr-HR')

class Header extends PureComponent {

	scrollToContact = () => {
		const { history } = this.props;

		history.push('/');
		const el = document.getElementById('contact-section');
		el && el.scrollIntoView({ behavior: 'smooth' });
	}

	render() {

		return (
			<header className="header">
				<div className="header__container">
					<div className="header__logo">
						<img src="/assets/acceligent_symbol.png" alt="logo"/>
					</div>
					<div className="header__links">
						<Link to="/" className="header__link">Home</Link>
						<span className="header__link" onClick={this.scrollToContact}>Contact us</span>
						<Link to="/privacy" className="header__link">Privacy</Link>
					</div>
				</div>
			</header>
		);
	}
}

export default Header;
