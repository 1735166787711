import React, { PureComponent } from 'react';

class More extends PureComponent {

	scrollToServices = () => {
		const el = document.getElementById('services-section');
		el && el.scrollIntoView({ behavior: 'smooth' });
	}

	render() {
		return (
			<div className="more">
				<div role="button" className="more__container" onClick={this.scrollToServices}>
					<div className="more__text">Check us out</div>
					<div className="more__icon">
						<span className="material-icons">keyboard_arrow_down</span>
					</div>
				</div>
			</div>
		);
	}
}

export default More;