import React, { Component } from 'react';
import PropTypes from 'prop-types';

class Section extends Component {

	render() {
		const {
			id,
			featured,
			fullHeight,
			children,
			automaticHeight,
			narrow,
		} = this.props;

		let wrapperClassName = 'home__section-wrapper';
		wrapperClassName = narrow ? `${wrapperClassName} home__section-wrapper--narrow` : wrapperClassName;

		return (
			<div
				id={id}
				className={`home__section${featured ? ' home__section--featured' : ''}${fullHeight ? ' home__section--full-height' : ''}${automaticHeight ? ' home__section--automatic-height' : ''}`}
			>
				<div className={wrapperClassName}>
					{children}
				</div>
			</div>
		);
	}
}

Section.propTypes = {
	id: PropTypes.string.isRequired,
	fetaured: PropTypes.bool,
	fullHeight: PropTypes.bool,
	automaticHeight: PropTypes.bool,
	narrow: PropTypes.bool
};

export default Section;