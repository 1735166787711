import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

class Cookies extends PureComponent {

	render() {
		const { id } = this.props;

		return (
			<>
				<p className="privacy-policy__heading" id={`section-${id}`}>
					{id}. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
				</p>
				<p>
					<span className="privacy-policy__highlight">In Short:</span>
					We may use cookies and other tracking technologies to collect and store your information.
				</p>
				<p>
					We may use cookies and similar tracking technologies (like web beacons and pixels) to access or store information. Specific information about how we use such technologies and how you can refuse certain cookies is set out in our Cookie Notice.
				</p>
			</>
		);
	}
}

Cookies.propTypes = {
	id: PropTypes.number
};

export default Cookies;