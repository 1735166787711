// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.header {
	z-index: 10;
	border: none;
	outline: none;
	display: block;
	max-width: 100vw;
	position: relative;
	height: 64px;
	position: sticky;
	top: 0;
	background-color: white;
}

.header__container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 0 2rem;
	height: 100%;
}

.header__logo {
	width: 40px;
}

.header__logo img {
	object-fit: contain;
	width: 100%;
}

.header__links {
	display: flex;
	flex-flow: row nowrap;
}

.header__link, .header__link:active, .header__link:visited, .header__link:focus {
	padding: .5rem;
	cursor: pointer;
	color: inherit;
	text-decoration: none;
}
`, "",{"version":3,"sources":["webpack://./src/components/Header/header.css"],"names":[],"mappings":"AAAA;CACC,WAAW;CACX,YAAY;CACZ,aAAa;CACb,cAAc;CACd,gBAAgB;CAChB,kBAAkB;CAClB,YAAY;CACZ,gBAAgB;CAChB,MAAM;CACN,uBAAuB;AACxB;;AAEA;CACC,aAAa;CACb,8BAA8B;CAC9B,mBAAmB;CACnB,eAAe;CACf,YAAY;AACb;;AAEA;CACC,WAAW;AACZ;;AAEA;CACC,mBAAmB;CACnB,WAAW;AACZ;;AAEA;CACC,aAAa;CACb,qBAAqB;AACtB;;AAEA;CACC,cAAc;CACd,eAAe;CACf,cAAc;CACd,qBAAqB;AACtB","sourcesContent":[".header {\n\tz-index: 10;\n\tborder: none;\n\toutline: none;\n\tdisplay: block;\n\tmax-width: 100vw;\n\tposition: relative;\n\theight: 64px;\n\tposition: sticky;\n\ttop: 0;\n\tbackground-color: white;\n}\n\n.header__container {\n\tdisplay: flex;\n\tjustify-content: space-between;\n\talign-items: center;\n\tpadding: 0 2rem;\n\theight: 100%;\n}\n\n.header__logo {\n\twidth: 40px;\n}\n\n.header__logo img {\n\tobject-fit: contain;\n\twidth: 100%;\n}\n\n.header__links {\n\tdisplay: flex;\n\tflex-flow: row nowrap;\n}\n\n.header__link, .header__link:active, .header__link:visited, .header__link:focus {\n\tpadding: .5rem;\n\tcursor: pointer;\n\tcolor: inherit;\n\ttext-decoration: none;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
