import React, { Component } from 'react';

import './contactForm.css';

class ContactForm extends Component {

	onSubmit = (e) => {
		e.preventDefault();
		const name = e.target.name.value;
		const subject = e.target.subject.value;
		const message = e.target.message.value;

		const a = document.createElement('a');
		a.target = '_blank';
		a.href = `mailto:dev@acceligent.com?subject=${subject}[${name}]&body=${message}`;
		a.click();
	}

	render() {
		return (
			<div className="contact-form">
				<form onSubmit={this.onSubmit}>

					<div className="form-group">
						<label htmlFor="name">Name</label>
						<input type="text" id="name" name="name" placeholder="Your name.." />
					</div>

					<div className="form-group">
						<label htmlFor="email">Email</label>
						<input type="email" id="email" name="email" placeholder="Your email.." />
					</div>

					<div className="form-group">
						<label htmlFor="subject">Subject</label>
						<input type="text" id="subject" name="subject" placeholder="Your Subject.." />
					</div>

					<div className="form-group">
						<label htmlFor="message">Message</label>
						<textarea id="message" name="message" placeholder="Write something.." rows={10} />
					</div>

					<button type="submit">Send</button>
				</form>
			</div>
		);
	}
}

export default ContactForm;