// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#services-section {
	height: auto;
}

.services__wrapper {
	padding: 5rem;
	height: 100%;
	width: 100%;
	display: flex;
	flex-flow: column nowrap;
	align-items: center;
}

.services__content {
	font-size: 2.5rem;
	font-weight: 200;
}

.services__container {
	display: flex;
	flex-flow: row wrap;
	padding: 0 1rem;
}

.services__title {
	font-size: 3rem;
	font-weight: 600;
	margin-bottom: 3rem;
}


@media only screen and (min-width: 320px) and (max-width: 991px) { 
	.services__content {
		font-size: 1.5rem;
	}
}

@media only screen and (min-width: 992px) { 
	.services__content {
		font-size: 2.5rem;
	}
}
`, "",{"version":3,"sources":["webpack://./src/pages/home/ServicesSection/services.css"],"names":[],"mappings":"AAAA;CACC,YAAY;AACb;;AAEA;CACC,aAAa;CACb,YAAY;CACZ,WAAW;CACX,aAAa;CACb,wBAAwB;CACxB,mBAAmB;AACpB;;AAEA;CACC,iBAAiB;CACjB,gBAAgB;AACjB;;AAEA;CACC,aAAa;CACb,mBAAmB;CACnB,eAAe;AAChB;;AAEA;CACC,eAAe;CACf,gBAAgB;CAChB,mBAAmB;AACpB;;;AAGA;CACC;EACC,iBAAiB;CAClB;AACD;;AAEA;CACC;EACC,iBAAiB;CAClB;AACD","sourcesContent":["#services-section {\n\theight: auto;\n}\n\n.services__wrapper {\n\tpadding: 5rem;\n\theight: 100%;\n\twidth: 100%;\n\tdisplay: flex;\n\tflex-flow: column nowrap;\n\talign-items: center;\n}\n\n.services__content {\n\tfont-size: 2.5rem;\n\tfont-weight: 200;\n}\n\n.services__container {\n\tdisplay: flex;\n\tflex-flow: row wrap;\n\tpadding: 0 1rem;\n}\n\n.services__title {\n\tfont-size: 3rem;\n\tfont-weight: 600;\n\tmargin-bottom: 3rem;\n}\n\n\n@media only screen and (min-width: 320px) and (max-width: 991px) { \n\t.services__content {\n\t\tfont-size: 1.5rem;\n\t}\n}\n\n@media only screen and (min-width: 992px) { \n\t.services__content {\n\t\tfont-size: 2.5rem;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
