import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Section from '../../../components/Section';

import CollectingInformation from './CollectingInformation';
import UsingInformation from './UsingInformation';
import SharingInformation from './SharingInformation';
import Cookies from './Cookies';
import GoogleMaps from './GoogleMaps';
import InternationalInformation from './InternationalInformation';
import InformationKeeping from './InformationKeeping';
import SecuringInformation from './SecuringInformation';
import PrivacyRights from './PrivacyRights';
import DoNotTrack from './DoNotTrack';
import California from './California';
import Updates from './Updates';
import Contact from './Contact';

import './privacyPolicy.css';

class PrivacyPolicySection extends PureComponent {

	scrollToSection = (id) => {
		const el = document.getElementById(`section-${id}`);
		const root = document.getElementById('root');

		console.log(!!el);

		if (!el) {
			return;
		}

		const offset = 64;
		const bodyRect = document.body.getBoundingClientRect().top;
		const elementRect = el.getBoundingClientRect().top;
		const elementPosition = elementRect - bodyRect;
		const offsetPosition = elementPosition - offset;

		root.scrollTo({
			top: offsetPosition,
			behavior: 'smooth'
		});

	}

	render() {
		const { id } = this.props;

		return (
			<Section id={id} automaticHeight={true} narrow={true} >
				<div className="privacy-policy__wrapper">
					<div className="privacy-policy__title">
						PRIVACY NOTICE
					</div>
					<div className="privacy-policy__subtitle">
						Last updated July 03, 2020
					</div>
					<div className="privacy-policy__container">
						<p>
							Thank you for choosing to be part of our community at Acceligent (“Company”, “we”, “us”, or “our”). We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about this privacy notice, or our practices with regards to your personal information, please contact us at <a href="mailto:dev@acceligent.com">dev@acceligent.com</a>.
						</p>
						<p>
							When you visit our <a href="https://app.acceligent.com">website</a> (the "Website"), use our mobile application, as the case may be (the "App") and more generally, use any of our services (the "Services", which include the Website and App), we appreciate that you are trusting us with your personal information. We take your privacy very seriously. In this privacy notice, we seek to explain to you in the clearest way possible what information we collect, how we use it and what rights you have in relation to it. We hope you take some time to read through it carefully, as it is important. If there are any terms in this privacy notice that you do not agree with, please discontinue use of our Services immediately.
						</p>
						<p>
							This privacy notice applies to all information collected through our Services (which, as described above, includes our Website and App), as well as any related services, sales, marketing or events.
						</p>
						<p>
							<b>Please read this privacy notice carefully as it will help you understand what we do with the information that we collect.</b>
						</p>
						<p className="privacy-policy__heading">
							TABLE OF CONTENT
						</p>
						<p>
							<span className="privacy-policy__link" onClick={this.scrollToSection.bind(this, 1)}>1. WHAT INFORMATION DO WE COLLECT?</span>

							<span className="privacy-policy__link" onClick={this.scrollToSection.bind(this, 2)}>2. HOW DO WE USE YOUR INFORMATION?</span>

							<span className="privacy-policy__link" onClick={this.scrollToSection.bind(this, 3)}>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</span>

							<span className="privacy-policy__link" onClick={this.scrollToSection.bind(this, 4)}>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</span>

							<span className="privacy-policy__link" onClick={this.scrollToSection.bind(this, 5)}>5. DO WE USE GOOGLE MAPS?</span>

							<span className="privacy-policy__link" onClick={this.scrollToSection.bind(this, 6)}>6. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?</span>

							<span className="privacy-policy__link" onClick={this.scrollToSection.bind(this, 7)}>7. HOW LONG DO WE KEEP YOUR INFORMATION?</span>

							<span className="privacy-policy__link" onClick={this.scrollToSection.bind(this, 8)}>8. HOW DO WE KEEP YOUR INFORMATION SAFE?</span>

							<span className="privacy-policy__link" onClick={this.scrollToSection.bind(this, 9)}>9. WHAT ARE YOUR PRIVACY RIGHTS?</span>

							<span className="privacy-policy__link" onClick={this.scrollToSection.bind(this, 10)}>10. CONTROLS FOR DO-NOT-TRACK FEATURES</span>

							<span className="privacy-policy__link" onClick={this.scrollToSection.bind(this, 11)}>11. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?</span>

							<span className="privacy-policy__link" onClick={this.scrollToSection.bind(this, 12)}>12. DO WE MAKE UPDATES TO THIS NOTICE?</span>

							<span className="privacy-policy__link" onClick={this.scrollToSection.bind(this, 13)}>13. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?</span>
						</p>
						<CollectingInformation id={1} />
						<UsingInformation id={2} />
						<SharingInformation id={3} />
						<Cookies id={4} />
						<GoogleMaps id={5} />
						<InternationalInformation id={6} />
						<InformationKeeping id={7} />
						<SecuringInformation id={8} />
						<PrivacyRights id={9} />
						<DoNotTrack id={10} />
						<California id={11} />
						<Updates id={12} />
						<Contact id={13} />

						<p className="privacy-policy__heading">
							HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?
						</p>
						<p>
							Based on the applicable laws of your country, you may have the right to request access to the personal information we collect from you, change that information, or delete it in some circumstances. To request to review, update, or delete your personal information, please submit a request form by clicking <a href="mailto:dev@acceligent.com">here</a>. We will respond to your request within 30 days.
						</p>
					</div>
				</div>
			</Section>
		);
	}
}

PrivacyPolicySection.propTypes = {
	id: PropTypes.string
};

export default PrivacyPolicySection;