import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import Section from '../../../components/Section';
// import ServiceCard from './ServiceCard/index.js';

// import data from '../../../data.json';

import './services.css';

class ServicesSection extends PureComponent {

	render() {
		const { id, featured, fullHeight } = this.props;

		return (
			<Section id={id} featured={featured} fullHeight={fullHeight}>
				<div className="services__wrapper">
					{/* <div className="services__title">
						Our services
					</div>
					<div className="services__container">
						{data.map((service, index) => (
							<ServiceCard key={index} {...service} />
						))}
					</div> */}
					<div className="services__content">
						Powerful. Fast. Easy-to-use. Acceligent is a comprehensive solution that helps contractors bid smarter, utilize resources more efficiently and improve job performance.
					</div>
				</div>
			</Section>
		);
	}
}

ServicesSection.propTypes = {
	id: PropTypes.string
};

export default ServicesSection;