import React, { PureComponent } from 'react';

import './footer.css';

class Footer extends PureComponent {

	render() {

		return (
			<footer>

			</footer>
		);
	}
}

export default Footer;